import '../sdist/tailwind.css';
import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import Alpine from 'alpinejs'
import initPhotoSwipeFromDOM from './photoswipe.js';
import { dinero, add, multiply, allocate, toDecimal } from 'dinero.js';
import { EUR } from '@dinero.js/currencies';
import htmx from 'htmx.org';

window.Alpine = Alpine
window.htmx = htmx;


function initMap(zoom, center){
  map = new google.maps.Map(document.getElementById('map'), {
    zoom: zoom || 11,
    center: center | { lat: 25.684411, lng: -100.317283 }
  });
  var mapReadyEvent = new CustomEvent('map-ready');
  window.dispatchEvent(mapReadyEvent);
}


document.addEventListener('alpine:init', () => {

  Alpine.data('LoadInvoice', () => ({
    loading: true,
    timeout: 1000,
    load() {
       // exists in the DOM
      this.loading = this.$el.querySelector('#InvoiceStatus') !== null;

      if (this.loading) {

        // increment the timeout until we hit 5 seconds
        if (this.timeout < 5000) {
          this.timeout = this.timeout + 1000;
        }

        htmx.trigger(this.$el, 'htmx:load');
        // set a timeout to check again in 5 seconds
        setTimeout(() => {
          this.load();
        }, this.timeout);
      }
    },
    init() {
      this.load();
    }
  }));

  Alpine.data('addTicket', (initialItem) => ({
    currency: EUR,
    item: initialItem,
    amount: 1,

    itemPrice() {
      return toDecimal(dinero({
        amount: this.item.price,
        currency: this.currency,
      }), ({ value, currency }) => `${value} ${currency.code}`);
    },

    add() {
      const currentAmount = this.$store.tickets.items.length;
      for (let i = 0; i < this.amount; i++) {
        Alpine.store('tickets').add({
          salut: '',
          title: '',
          lastname: '',
          firstname: '',
          company: '',
          price_id: this.item.id,
          price: this.item.price,
          name: this.item.name,
          amount: 1,
        });
      }
      const editNext = () => this.$dispatch('edit-ticket', { index: currentAmount });
      this.$nextTick(editNext);
      this.amount = 1;
    },

  }));

  Alpine.data('ticketEdit', (itemIndex) => ({
    show: false,
    myindex: itemIndex,
    salut: '', title: '', lastname: '', firstname: '',
    company: '',
    validate: itemIndex === 0,
    index: itemIndex,

    valid_firstname() {
      return this.firstname.length > 0;
    },

    valid_lastname() {
      return this.lastname.length > 0;
    },
    valid_salut() {
      return this.salut.length > 0;
    },

    valid() {
      return this.valid_firstname() && this.valid_lastname() && this.valid_salut();
    },

    save() {
      const item = this.$store.tickets.items[this.index];
      this.validate = true;
      if (item && this.valid()) {
        item.salut = this.salut;
        item.title = this.title;
        item.lastname = this.lastname;
        item.firstname = this.firstname;
        item.company = this.company;
        this.show = false;
      }
    }
  }));

  Alpine.data('Form', () => ({
    error: false,
    submit() {
      let data = new FormData(this.$el);
      this.$store.tickets.processing = true;
      fetch(this.$el.getAttribute('action'), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',

        },
        body: data,
      }).then(response => response.json()).then(data => {
        console.log(data);
        if (data.success) {
          window.location.href = data.redirect;
        }
      })
			.catch(e => {
        console.error(e);
				this.error = true;
			})
    }
  }));

  Alpine.data('ticketCart', () => ({
    currency: EUR,
    showOverlay: false,

    format(price) {
      return toDecimal(price, ({ value, currency }) => `${value} ${currency.code}`);
    },
    ticketName(item) {
      return [item.salut, item.title, item.firstname, item.lastname].filter(n => n).join(" ");
    },

    ticketHasData(item) {
      return [item.salut, item.title, item.firstname, item.lastname].filter(n => n).length;
    },

    convertedItems() {

      return this.$store.tickets.items.map((item) => ({
        ...item,
        price: dinero({
          amount: item.price,
          currency: this.currency,
        }),
      }));
    },

    calculatedItems() {
      const zero = dinero({ amount: 0, currency: this.currency });

      return this.convertedItems().reduce(
        (acc, item) => {
          const count = acc.count + item.amount;
          const subtotal = add(acc.subtotal, multiply(item.price, item.amount));

          return { count, subtotal };
        },
        { count: 0, subtotal: zero }
      );
    },
    shipping() {
      const zero = dinero({ amount: 0, currency: this.currency });
      return zero;
    },

    total() {
      return [
        this.calculatedItems().subtotal,
        this.shipping(),
      ].reduce(add);
    },

    vatAmount() {
      const vatRate = Alpine.store('vatRate');
      const [vatAmount] = allocate(this.total(), [
        100 - 10000 / (100 + vatRate),
        10000 / (100 + vatRate),
      ]);

      return vatAmount;
    },

    submit() {
      this.$store.tickets.validate = true;
      if (this.$store.tickets.valid() && this.convertedItems().length > 0) {
        this.$dispatch('checkout');
      }
    }
  }));

  Alpine.store('vatRate', 19);

  Alpine.store('tickets', {
    email: '',
    validate: false,
    billing: false,
    birthday_required: false,
    shipping_same_as_billing: true,
    items: [],
    shipping_name: '',
    shipping_additional: '',
    shipping_street: '',
    shipping_postal: '',
    shipping_city: '',
    salut: '',
    title: '',
    firstname: '',
    lastname: '',
    company: '',
    position: '',
    additional: '',
    birthday: '',
    street: '',
    postal: '',
    city: '',
    processing: false,
    limit: null,

    valid_email() {
      return this.email.length > 0;
    },

    valid_salut() {
      return this.salut.length > 0;
    },

    valid_firstname() {
      return this.firstname.length > 0;
    },

    valid_lastname() {
      return this.lastname.length > 0;
    },

    valid_company() {
      return this.company.length > 0;
    },

    valid_street() {
      return this.street.length > 0;
    },

    valid_postal() {
      return this.postal.length > 0;
    },

    valid_city() {
      return this.city.length > 0;
    },

    valid_birthday() {
      return !this.birthday_required || this.birthday.length > 0;
    },
    valid_shipping_name() {
      return this.shipping_same_as_billing || this.shipping_name.length > 0;
    },
    valid_shipping_street() {
      return this.shipping_same_as_billing || this.shipping_street.length > 0;
    },
    valid_shipping_postal() {
      return this.shipping_same_as_billing || this.shipping_postal.length > 0;
    },
    valid_shipping_city() {
      return this.shipping_same_as_billing || this.shipping_city.length > 0;
    },

    valid_limit() {
      return this.limit === null || this.limit >= this.items.length;
    },

    valid() {
      const billing_is_valid = [
        this.valid_salut(), this.valid_firstname(), this.valid_lastname(), this.valid_company(), this.valid_street(), this.valid_postal(), this.valid_city(), this.valid_birthday(),
      ].every(n => n);

      const shipping_is_valid = [
        this.valid_shipping_name(), this.valid_shipping_street(), this.valid_shipping_postal(), this.valid_shipping_city(),
      ].every(n => n);

      return this.valid_email() && billing_is_valid && shipping_is_valid && this.valid_limit();
    },

    set_limit(limit) {
      this.limit = limit;
    },

    add(item) {
      this.items.push(item);
    },

    remove(index) {
      this.items.splice(index, 1);
    },
  });
})

document.addEventListener('DOMContentLoaded', () => {

  //var spy = new Gumshoe('#ScrollMenu a');
  //var spy = new Gumshoe('#MobileScroll a');

  initPhotoSwipeFromDOM('.gallery-line figure');

});

Alpine.start()
